import { FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export class CustomValidators {

    static atLeastOneInputHasValue (form: FormGroup): ValidationErrors {

        let error = true;

        Object.keys(form.controls).forEach(key => {
            if (form.get(key).value !== '') {
                error = false;
                return;
            }
      });

        const message = {
            'atLeastOneInputHasValue': {
              'message': 'Por favor ingrese al menos un valor'
            }
          };

          return error ? message : null;
    }

    // https://medium.com/ngx/3-ways-to-implement-conditional-validation-of-reactive-forms-c59ed6fc3325
    static requiredIfValidator(predicate) {
      return (formControl => {
        if (!formControl.parent) {
          return null;
        }
        if (predicate()) {
          return Validators.required(formControl);
        }
        return null;
      });
    }
}
