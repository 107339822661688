import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Servers } from "../config/api";
import { handleError } from "../util/error-handler";


@Injectable({
  providedIn: 'root'
})
export class AsientoContableService {

  idPlanCuenta: any;
  monto: any;
  tipoTransaccion: any;
  codigoCuenta: any;
  nombreCuenta: any;
  debe: any;
  debeEnGs: any;
  haber: any;
  haberEnGs: any;
  numeroLinea: any;
  rows:Array<{idPlanCuenta:any, monto:any, tipoTransaccion:any, codigoCuenta:any, nombreCuenta:any, debe:any; debeEnGs:any, haber:any, haberEnGs:any, numeroLinea:any}> = [];
  public asientoOk: boolean = false;
  public cotizacion: any;

  constructor(private http: HttpClient) {
    this.idPlanCuenta = null;
    this.monto = null;
    this.tipoTransaccion = null;
    this.codigoCuenta = null;
    this.nombreCuenta = null;
    this.debe = null;
    this.debeEnGs = null;
    this.haberEnGs = null;
    this.haber = null;
    this.numeroLinea = 0;
  }

  private asientoContableUrl = Servers.Contabilidad.baseUrl + 'dbo.asiento-contable';
  listar(params: any): Observable<any> {

    return this.http.get<any[]>(this.asientoContableUrl, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .pipe(
        tap((response: any) => {
          console.log("login")
        }),
        catchError(handleError('listar', {}))
      );
  }
}