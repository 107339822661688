import { NgModule } from "@angular/core";
import { RouterModule } from '@angular/router';
import { ThousandsPipe } from './../pipes/thousands.pipe';
import { NumberPipe } from './../pipes/number.pipe';
import { NgxPermissionsModule } from "ngx-permissions";

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [ 
    ThousandsPipe,
    NumberPipe
  ],
  exports: [
    ThousandsPipe,
    NumberPipe,
    NgxPermissionsModule
  ]
})
export class SharedModule {}