export enum CampoConfiguracion {
    CODIGO_CIERRE_MES_ACTIVO = 'CIEMESACT',
    CIERRE_MES_ACTIVO_TRUE = 'TRUE',
    CIERRE_MES_ACTIVO_FALSE = 'FALSE',
    MES_CONTABLE = 'MESCONTA',
    ANHO_CONTABLE = 'ANHOCONTA',
    ANHO_ASIENTO = 'ANHOASIENT',
    ULTIMO_NRO_ASIENTO = 'NROASIENTO',
    CANT_DIA_DEVOLUCION = 'DIADEVOLU'
}
